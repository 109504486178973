import { m } from 'framer-motion';

import { useBreakpoint } from '@/lib/hooks/breakpoint';

type Props = {
  onClose: () => void;
  children: React.ReactNode;
};

export function AnimateToast({ children, onClose }: Props) {
  const isMd = useBreakpoint('md');

  if (isMd === null) {
    return null;
  }

  const isMobile = !isMd;

  return (
    <m.div
      className="fixed bottom-0 right-0 z-40 w-full md:bottom-auto md:right-16 md:top-12 md:w-max"
      initial={{
        opacity: isMobile ? 1 : 0,
        translateY: isMobile ? '100%' : '-100%',
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      exit={{
        opacity: isMobile ? 1 : 0,
        translateY: isMobile ? '100%' : '-100%',
      }}
      drag={isMobile ? 'y' : undefined}
      dragConstraints={{ top: 0, bottom: 0 }}
      dragElastic={0.4}
      transition={{
        type: 'spring',
        damping: 15,
      }}
      onDragEnd={(_, info) => {
        if (
          info.offset.y > 200 ||
          (info.velocity.y > 1000 && info.delta.y > 0)
        ) {
          onClose();
        }
      }}
    >
      {children}
    </m.div>
  );
}
