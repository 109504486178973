export const EXPRESS_CHECKOUT_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_EXPRESS_CHECKOUT === 'true';

export const ENABLE_GIFTCARD_PAYMENT =
  process.env.NEXT_PUBLIC_ENABLE_GIFTCARD_PAYMENT === 'true';

export const BOOKING_WIDGET_NEXT_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_BOOKING_WIDGET_NEXT === 'true';

export const ALLOW_CHANGING_CURRENCY_POST_CART =
  BOOKING_WIDGET_NEXT_ENABLED ||
  process.env.NEXT_PUBLIC_ALLOW_CHANGING_CURRENCY_POST_CART === 'true';

export const BOOKING_WIDGET_NEXT_FLYBUS_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_BOOKING_WIDGET_NEXT_FLYBUS === 'true';

export const BOOKING_WIDGET_NEXT_ENABLE_KEY_BENEFITS_SECTION =
  process.env.NEXT_PUBLIC_BOOKING_WIDGET_NEXT_ENABLE_KEY_BENEFITS_SECTION ===
  'true';

export const PDP_NEW_DESIGN_ENABLED =
  process.env.NEXT_PUBLIC_PDP_NEW_DESIGN_ENABLED === 'true';

export const ENABLE_LEGAL_IN_BOOKING_FLOW =
  process.env.NEXT_PUBLIC_ENABLE_LEGAL_IN_BOOKING_FLOW === 'true';
