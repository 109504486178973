import clsx from 'clsx';
import type { SVGProps } from 'react';

import type { IconName } from './types';

export function Icon({
  iconName,
  size = 16,
  className,
  ...props
}: SVGProps<SVGSVGElement> & {
  iconName: IconName;
  size?: number;
  childClassName?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      {...props}
      className={clsx('shrink-0', className)}
    >
      <use href={`/icons/sprite.svg#${iconName}`} />
    </svg>
  );
}
